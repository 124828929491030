body {
    background-color: #f5f5f5;
}

.idp-form {
    margin-top: 30px;
}

*::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
}

*::-webkit-scrollbar-thumb {
    background-color: #00B8F1;
}

a {
    text-decoration: none;
}